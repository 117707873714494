import React from 'react';
import './TollTransactionList.css';
import TollTransaction from './TollTransaction';

function TollTransactionList(props: any) {
  const { tollData } = props;
  console.log(tollData)
  
  return (
    
<div className="card toll-transaction-list mt-4 mb-4" >
  <div className="card-body toll-transaction-list-body">
  <h5 className="card-title pb-3">Toll Transactions</h5>

    {
      tollData?.tolls?.map((t:any) => {
        console.log(t);
        return <TollTransaction toll={t} key={t.id}/>
        
      })

    }
    
  </div>
</div>
  );
}

export default TollTransactionList;
