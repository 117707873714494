import React, { useEffect, useState } from 'react';
import './TollTransaction.css';
import { parseISO, format } from 'date-fns';
import { TollTransactionBackendData } from '../../interfaces';
import { NumericFormat, NumberFormatBase } from 'react-number-format';

function TollTransaction(props: any) {
  const { toll } = props;
  const [transaction, setTransaction] = useState<TollTransactionBackendData>({});

  useEffect(() => {
    const transaction = { ...toll };
    transaction.exitDateTime = format(parseISO(toll.exitDateTime), 'MMM d, yyyy hh:mm a');
    setTransaction(transaction);

  }, [toll])

  function MyCustomNumberFormat(props: any) {
    const format = (numStr: any) => {
      console.log({numStr})
      if (numStr === '') return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      }).format(numStr);
    };
  
    return <NumberFormatBase {...props} format={format} />;
  }

  return (

    <div className="card mb-3 toll-transaction" >
      <div className="card-body">
        <div className='row'>
          <div className='col-9'>
            <span className='fs-6 fw-bold toll-location'>{toll.location}</span>


            <br />
            <span className=''>
              {transaction?.exitDateTime}
            </span>

          </div>
          <div className='col-3'>
            <span className='fs-6 fw-bold toll-amount'>
              {/* $ <NumericFormat value={transaction.amount} displayType="text" decimalScale={2} format="###"/> */}
              <MyCustomNumberFormat value={parseFloat(transaction.amount!)} displayType="text" />
              </span>
            


          </div>
        </div>
      </div>


    </div>
  );
}

export default TollTransaction;
