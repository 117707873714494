import React, { useEffect, useState } from 'react';
import './View.css';
import Logo from './text_logo.jpg';
import VehicleInfo from './VehicleInfo';
import TotalAmount from './TotalAmount';
import TollTransactionList from './TollTransactionList';
import { useSearchParams } from "react-router-dom";
import {parseISO, format} from 'date-fns';
import { TollBackendData } from '../../interfaces';


function View() {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const deviceId = searchParameters.get("did");
  const invoiceId = searchParameters.get("iid");
  const [tollData, setTollData] = useState<TollBackendData|undefined>();

  console.log(deviceId)
  console.log(invoiceId);

  useEffect(() => {
    if(invoiceId) {
      get_receipt_data(invoiceId);
    }
    
  }, [invoiceId]);

  useEffect(() => {
    if(deviceId) {
      request_receipt(deviceId);
    }
    
  }, [deviceId]);
  function get_receipt_data(invoiceId: string) {
    fetch('/receipt/?iid='+invoiceId)
    .then(res => res.json())
    .then(data => {
      console.log({data});
      const tollData = data[0] as TollBackendData;
      if(tollData?.vehicleOut) {
        const vehicleOut = parseISO(tollData?.vehicleOut!);
        tollData.vehicleOut = format(vehicleOut, 'MMM d, yyyy hh:mm a');
      }

      if(tollData?.currentTime) {
        const currentTime = parseISO(tollData.currentTime);
        tollData.currentTime = format(currentTime, 'MMM d, yyyy hh:mm a')
      }
      setTollData(tollData);
    }).catch(err => {
      console.log({err});
    });
  }
  function request_receipt(deviceId: string) {
    const options = {
      method: 'POST',
      headers:  {'Content-Type': 'application/json'},
      body: JSON.stringify({deviceId: deviceId})
    };

    fetch('/send-receipt-link/?did='+deviceId, options)
    .then(res => res.json())
    .then(data => {});
  }

  function PageData(props: any) {
    if(deviceId) {
      return <>
     <p>Thank You for using TollSpot. For your safety & privacy, we will send you a link to your bill via SMS to the phone number on file.</p>
     <p>If you don't receive a SMS or want to change your primary phone number, please contact your rental agency.</p>
      </>
    }

    if(invoiceId) {
      return <>
       <p>Thank You for using TollSpot. These are the tolls on your rental as of {tollData?.currentTime}. Your tolls are charged to the VISA on file.</p>
      <VehicleInfo tollData={tollData}/>
      <TotalAmount tollData={tollData}/>
      <TollTransactionList tollData={tollData}></TollTransactionList>
      <p>If you have any questions or concerns please email <a href="mailto:support@tollspot.com">support@tollspot.com</a></p>
      </>
    }
    return <></>
  }

  return (
    
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
          <span className="fs-4">TollSpot</span>
        </a>
      </header>
      <main>
        <PageData/>
      

      
      </main>
    </div> 

    
  );
}

export default View;
