import React, { useState, useEffect } from 'react';
import './VehicleInfo.css';
import {TollBackendData, VehicleBackend} from '../../interfaces';
import {parseISO, format} from 'date-fns';

function VehicleInfo(props: any) {
  const [vehicleOut, setVehicleOut] = useState<Date>(new Date());
  const [vehicle, setVehicle] = useState<VehicleBackend>({});


  useEffect(() => {
    const { tollData } = props;
    if(tollData) {
      setVehicle(tollData.vehicle);
    }
    // if()
    // const { vehicle } = tollData;
    // const vehicleOut = parseISO(vehicle?.vehicleOut);
    // setVehicle(vehicle);
    // console.log({vehicle, vehicleOut})
    // setVehicleOut(format(vehicleOut, 'MMM d, yyyy hh:mm a'));
  }, [props])

  return (
    

  <div className="card vehicle-info mb-4" >
    <div className="card-body">
  
      <h6 className="card-subtitle mb-2 text-muted">Vehicle Info</h6>
      <div className="card-text">
        <div className='row'>
          <div className='col-8'>
            <span className='fs-4 fw-bold'>{vehicle?.year} {vehicle?.make} {vehicle?.model}</span> <br/>
            {/* <span className='fs-6'>{format(vehicleOut, 'MMM d, yyyy hh:mm a')}</span> */}
          </div>
          <div className='col-4'>
          <p className="fs-6 fw-bold text-center">{vehicle?.licensePlateState}<br/>{vehicle?.licensePlate}</p>
          </div>
        </div>
      </div>
  
    </div>
  </div>

  );
}

export default VehicleInfo;
