import React from 'react';
import './TotalAmount.css';

function TotalAmount(props: any) {
  const {tollData} = props;
  console.log(tollData)
  return (
    
<div className="card total-amount" >
  <div className="card-body">
    
    {/* <h6 className="card-subtitle mb-2 text-muted">Total</h6> */}
    <h5 className="card-title">
      <div className='row'>
        <div className='col-8'>
          Total
        </div>
        <div className='col float-right'>
        <div className='d-flex justify-content-end'>$ {tollData?.amount}</div>
        </div>
      </div>
      
    </h5>

    
  </div>
</div>
  );
}

export default TotalAmount;
